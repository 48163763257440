const GetAuthorName = {
    3: 'GERENA.BG',
    7: 'АДРИАНА ДИМИТРОВА',
    15: 'АНГЕЛ ЛАЗАРОВ',
    11: 'ВАСИЛ КОЛЕВ',
    19: 'ИВАН ТЕПАВИЧАРОВ',
    2: 'КРАСИМИР ТОДОРОВ',
    28: 'КРИСТИЯН КИРИЛОВ',
    16: 'МАЧ ТЕЛЕГРАФ',
    8: 'МИРЕЛА ЯКОВА',
    4: 'НИКОЛА ПЕТРОВ',
    20: 'ПАОЛИНА ДОНКОВА',
    13: 'СК ЛЕВСКИ',
    14: 'СТАНИМИР БАКАЛОВ',
    12: 'ТЕМА СПОРТ',
};

export default GetAuthorName;