import React from "react";
import MainContent from "../components/MainContent";

const Volleyball = ()=> {
    return(
        <MainContent
        category={213}
        >

        </MainContent>
    )
};

export default Volleyball;