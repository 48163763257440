import React from "react";

const Privacy = ()=> {
    return(
        <main className="home-container" >
            <div className="home-wrapper">
                <div className="privacy=wrapper">
                    <ul className="privacyUl">
                        <li>
                            <span>А. Въведение</span>
                            <div>
                                <p>1. Поверителността на уебсайта ни е много важна за нас и сме ангажирани да я пазим. Тази полица обяснява какво ще направим с личните ви данни.</p>
                                <p>2. Съгласието за нашата употребата на бисквитки в съответствие с условията на тази полица, когато посетите сайта ни за пръв път, ни позволява да използваме бисквитки всеки път, в който посещавате уебсайта ни.</p>
                            </div>
                        </li>

                        <li>
                            <span>Б. Събиране на лични данни</span>
                            <div>
                                <p>1. Информация за компютъра ви, включваща вашия IP адрес, географско местоположение, тип и версия на браузъра ви и операционна система;</p>
                                <p>2. Информация за посещенията ви и употребата на този уебсайт, включително референтния източник, продължителност на посещение, гледания на страници и уебсайт навигация;</p>
                                <p>3. Информация като имейл адреса ви, която въвеждате, когато се регистрирате в нашия уебсайт;</p>
                                <p>4. Информация като името ви и имейл адреса ви, които сте въвели, за да настроите абонаменти към нашите имейли и/или бюлетини;</p>
                                <p>5. Информация, генерирана докато използвате нашия уебсайт, включително кога, колко често и при какви обстоятелства го използвате;</p>
                                <p>6. Информация, съдържаща се във всяка комуникация с нас, изпратена ни чрез имейл или чрез уебсайта ни, включително съдържанието на комуникацията и метаданните;</p>
                                <p>7. Всяка друга лична информация, която ни изпращате.</p>
                            </div>
                        </li>

                        <li>
                            <span>В. Използване на личната Ви информация</span>
                            <div>
                                <p>1. Администриране на нашите уебсайт и бизнес;</p>
                                <p>2. Персонализиране на уебсайта ни за вас;</p>
                                <p>3. Ва ви изпращаме немаркетингови рекламни комуникации;</p>
                                <p>4. Да ви изпращаме маркетингови комуникации, свързани с нашия бизнес или бизнесите на внимателно подбрани трети лица, които смятаме, че биха представлявали интерес за вас, чрез публикация или, ако конкретно сте се съгласили с това, чрез имейл или подобен канал (можете да ни информирате по всяко време, ако вече не желаете да получавате маркетингови комуникации);</p>
                                <p>5. Осигуряване на статистическа информация за потребителите ни на трети лица (но тези трети лица няма да могат да идентифицират отделните потребители от тази информация);</p>
                                <p>6. Потвърждение на съгласие с нашите правила и условия, ръководени от употребата на уебсайта ни (включително показването на лични съобщения, изпратени чрез услугата на сайта ни за лични съобщения)</p>
                                <p>7. Други цели.</p>
                            </div>
                        </li>

                        <li>
                            <span>Г. Оповестяване на лични данни</span>
                            <div>
                                <p>1. До степента, в която сме длъжни да го направим по закон;</p>
                                <p>2. За да установим, упражним или защитим нашите права (включително осигуряване на информация на други лица за целите на предотвратяване на измама и намаляване на кредитен риск);</p>
                                <p>3. На всеки човек, който вярваме, че може да с яви пред съд или друг подобен орган, за оповестяване на тази лична информация, където според нашето разумно мнение подобен съд или орган може да нареди оповестяване на тази лична информация.</p>
                                <p>4.</p>
                                <p>5.</p>
                            </div>
                        </li>

                        <li>
                            <span>Д. Защита на личната Ви информация</span>
                            <div>
                                <p>1. Ще предприемем разумни технически и организационни мерки за предотвратяване на загуба, злоупотреба или промяна на личната ви информация.</p>
                                <p>2. Ще съхраняваме цялата лична информация, която осигурявате на нашите сигурни (защитени от парола и защитна стена) сървъри.</p>
                                <p>3. Вие потвърждавате, че предаването на информация по интернет само по себе си е несигурно, и ние не можем да гарантираме сигурността на данни, изпратени по интернет.</p>
                            </div>
                        </li>

                        <li>
                            <span>Е. Бисквитки и трети страни</span>
                            <div>
                                <p>1. Нашият уебсайт включва хибервръзки към уебсайтове на трети страни и подробности за тях. Ние нямаме контрол и не сме отговорни за политиките за поверителност и практиките на трети страни.</p>
                                <p>2. Използваме Google Analytics и Adsense в уебсайта си, за да разпознаваме компютър, когато един потребител посещава уебсайта / следи потребителите, докато те управляват уебсайта / подобрява използваемостта на уебсайта / анализира употребата на уебсайта / администрира уебсайта / предотвратява измама, за да подобри сигурността на уебсайта / персонализира уебсайта за всеки потребител / цели се в рекламите, които може да не са в интерес на конкретните потребители / обяснете вашата цел(и);</p>
                                <p>3. Повечето браузъри ви позволяват да откажете приемането на бисквитки</p>
                                <p>4. Блокирането на всички бисквитки ще има отрицателно влияние върху използваемостта на много сайтове. Ако блокирате бисквитките, няма да можете да използвате всички свойства на уебсайта ни.</p>
                                <p>5. Изтриването на бисквитките ще има отрицателно влияние върху използваемостта на много уебсайтове.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default Privacy