import React, { useEffect } from "react";

const AdComponent = ({className, adslot, adclient})=> {
    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {
            console.log(e)
        }

    },[]);


    return (
        <div className="ad-wrap">
            <ins className={className ? `adsbygoogle ${className}` : 'adsbygoogle'}
                style={{ display: "block", textAlign: "center" }}
                data-ad-client={adclient}
                data-ad-slot={adslot}
                data-ad-format="auto"
                data-full-width-responsive="true"
                >
            </ins>
        </div>
    )
};

export default AdComponent;