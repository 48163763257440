import React from "react";
import MainContent from "../components/MainContent";

const Gymnastics = ()=> {
    return (
        <MainContent
        category={217}
        >

        </MainContent>
    )
};

export default Gymnastics;