import React from "react";
import MainContent from "../components/MainContent";

const Others = ()=> {
    return(
        <MainContent
        category={1}
        >

        </MainContent>
    )
};

export default Others;