import React from "react";
import MainContent from "../components/MainContent";

const LevskiEu = () => {
    return(
        <MainContent
        category={211}
        >

        </MainContent>
    )
};

export default LevskiEu;