import React from "react";
import MainContent from "../components/MainContent";

const LevskiYouths = ()=> {
    return (
        <MainContent
        category={209}
        >

        </MainContent>
    )
}

export default LevskiYouths;