import React, { useEffect, useRef, useState } from "react";
import FrontNews from "./components/FrontNews";
import AdComponent from './components/AdComponent';
import LoaderPost from "./components/LoaderPost";
import FrontNewsSlider from "./components/FrontNewsSlider";
import scrollToTop from "./hooks-and-funcs/ScrollToTop";
import Flashscore from "./components/Flashscore";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useTheme } from "./hooks-and-funcs/ThemeStateContext";
import GptAdComponent from "./components/GptAd";

const HomePage = ()=> {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const {isDark, setIsDark} = useTheme();

    const changeTheme = ()=> {
        setIsDark(!isDark);
    }

    const fetchAll = async ()=> {
        setIsLoading(true)
        try {
            const urls = [
                'https://wp.gerena.bg/wp-json/wp/v2/posts?categories=216&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?categories=212&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?categories=213&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?categories=217&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?categories=1&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?author=12&per_page=20&orderby=date',
                'https://wp.gerena.bg/wp-json/wp/v2/posts?author=16&per_page=20&orderby=date',
            ];

            const responses = await Promise.all(urls.map(url => fetch(url, {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache'
                }
              }
              )));
            const data = await Promise.all(responses.map(res => res.json()));
            setData(data);



        } catch (error) {
            console.error('Error fetching data:', error)
        }
        finally{
            setIsLoading(false)
        }
    }

    useEffect(()=> {
        fetchAll();
    },[])


    return (
        <>
        {
        isLoading 
        ? 
        (<LoaderPost></LoaderPost>)
        :
        (
          <main className="home-container"> 
                  <div className={isDark ? 'home-wrapper isDark' : 'home-wrapper'}>
                        <div className="theme-switcher-container">
                            <DarkModeSwitch
                            checked={isDark}
                            onChange={changeTheme}
                            />
                        </div>
                        <div className="home-page-flex-wrapper">
                            <section>
                            <FrontNews
                            items = {data[0]}
                            title = {'ФУТБОЛЕН КЛУБ ЛЕВСКИ'}
                            />
                            </section>

                            {/* 
                            <section className="gpt-ad-section">
                                <GptAdComponent/>
                            </section>
                            */}

                            <div className="flash-home">
                                <Flashscore></Flashscore>
                            </div>
        
                            <section>
                                <FrontNews
                                items={data[1]}
                                title={"БАСКЕТБОЛ"}
                                />
                            </section>
        
        
                            <section className="volley-section">
                                <FrontNews
                                items={data[2]}
                                title= {"ВОЛЕЙБОЛ"}
                                />
                            </section>

                            <section className="home-ad">
                                    <AdComponent
                                    className= {'front-ad'}
                                    adslot = {'8392130330'}
                                    adclient= {'ca-pub-2309670898258044'} 
                                />
                            </section>

                            <div className="home-page-flex-sliders">
                                <FrontNewsSlider
                                data = {data[5]}
                                title={'ТЕМА СПОРТ'}
                                classNames = {{
                                    container: 'tema-sport-container',
                                    carouselWrapper: 'tema-sport-wrapper',
                                    carouselContainer: 'my-carousel-wrapper',
                                    sectionContainer: 'tema-sport-section',
                                }}
                                >
                                </FrontNewsSlider>

                                <FrontNewsSlider
                                data = {data[6]}
                                title={'МАЧ ТЕЛЕГРАФ'}
                                classNames = {{
                                    container: 'match-telegraph-container',
                                    carouselWrapper: 'match-telegraph-wrapper',
                                    carouselContainer: 'my-carousel-wrapper-telegraph',
                                    sectionContainer: 'telegraph-section',
                                }}
                                >
                                </FrontNewsSlider>
                            </div>

                            {
                                /* 
                                <section className="home-ad">
                                    <AdComponent
                                    className={'front-ad'}
                                    adslot={'6863426247'}
                                    adclient={'ca-pub-2309670898258044'}  
                                    />
                                </section> 
                                */
                            }
                            
                            <section className="gym-section">
                                <FrontNews
                                items={data[3]}
                                title= {"ГИМНАСТИКА"}
                                />
                            </section>
        
        
                            <section>
                                <FrontNews
                                items={data[4]}
                                title= {"ДРУГИ"}
                                />
                            </section>
                        </div>
                  </div>
          </main>
       )}
        </>
      )}

export default HomePage;
