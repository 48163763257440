import React, { createContext, useContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({children}) => {
  const [isDark, setIsDark] = useState(()=> {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });
  useEffect(()=> {
    localStorage.setItem('theme', JSON.stringify(isDark));
    if(isDark){
        document.body.classList.remove('body-theme-light');
        document.body.classList.add('body-theme-dark');
    }
    else{
        document.body.classList.remove('body-theme-dark');
        document.body.classList.add('body-theme-light');
    }
  }, [isDark]);
  return(
    <ThemeContext.Provider
    value={{isDark, setIsDark}}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = ()=> {
  return useContext(ThemeContext);
}