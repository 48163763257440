import React from "react";
import MainContent from "../components/MainContent";

const Basketball = ()=> {
    return (
        <MainContent
        category={212}
        >

        </MainContent>
    )
};

export default Basketball;