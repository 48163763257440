import React, { useState } from "react";
import { useEffect } from "react";
import { calendar } from "../assetsExport";
import postDate from "../hooks-and-funcs/postDate";
import { Link } from "react-router-dom";
import findPath from "../hooks-and-funcs/FindPath";
import scrollToTop from "../hooks-and-funcs/ScrollToTop";
import Carousel from "react-multi-carousel";
import findCategory from "../hooks-and-funcs/FindCategory";
import { useTheme } from "../hooks-and-funcs/ThemeStateContext";
import he from 'he';

const FrontNews = ({items, title})=> {
    const [filtered, setFiltered] = useState([]);
    const {isDark, setIsDark} = useTheme();
    useEffect(()=> {
        setFiltered(()=> {
            return items.slice(3)
          });
    },[items])

    const decodedPostTitles = items.map((item, index)=> {
      return(
        he.decode(item.title.rendered)
      )
    })

    const decodedFilteredTitles = items.slice(3).map((item, index)=> {
      return(
        he.decode(item.title.rendered)
      )
    })

    return(
        <>
        {items && items.length > 0 && filtered.length > 0 && (
          <>
          <div className={isDark ? "home-category-title-dark" : "home-category-title"}>
              <h2 className={isDark ? "home-category-title-dark" : "home-category-title"}>{title}</h2>
          </div>
            <div className="front-news-container">
                    <a
                    href={`/${findPath(items[0].categories[0])}/${items[0].id}`}
                    className="front-news-wrapper-big"
                    >
                                    <img src={items[0].jetpack_featured_media_url} alt="" />
                                    <div className="front-news-text-container">
                                        <div className="front-news-text-big"
                                        dangerouslySetInnerHTML={{__html:
                                            decodedPostTitles[0]
                                        }}
                                        >
                                            
                                        </div>
                                        <div className="article-info"><span> <img src={calendar} width='12px' alt="" /> </span> {postDate(items[0].date)} <span className="article-category">{findCategory(items[0].categories[0])}</span></div>
                                    </div>
                    </a>
                    <div className="front-news-wrapper-small">
                        <a
                        href={`/${findPath(items[1].categories[0])}/${items[1].id}`}
                        className="front-first-small"
                        >
                                    <img src={items[1].jetpack_featured_media_url} alt="" />
                                    <div className="front-news-text-container">
                                        <div className="front-news-text-small"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                            decodedPostTitles[1].length >= 50
                                            ? `${decodedPostTitles[1].slice(0, 49)}...`
                                            : decodedPostTitles[1],
                                        }}
                                        >
                                        </div>
                                        <div className="article-info"><span> <img src={calendar} width='12px' alt="" /> </span> {postDate(items[1].date)} <span className="article-category">{findCategory(items[1].categories[0])}</span></div>
                                    </div>
                        </a>
                        <a
                        href={`${findPath(items[2].categories[0])}/${items[2].id}`}
                        className="front-second-small"
                        >
                                    <img src={items[2].jetpack_featured_media_url} alt="" />
                                    <div className="front-news-text-container">
                                        <div className="front-news-text-small"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                            decodedPostTitles[2].length >= 50
                                            ? `${decodedPostTitles[2].slice(0, 49)}...`
                                            : decodedPostTitles[2],
                                        }}
                                        >
                                        </div>
                                        <div className="article-info"><span> <img src={calendar} width='12px' alt="" /> </span> {postDate(items[2].date)} <span className="article-category">{findCategory(items[2].categories[0])}</span></div>
                                    </div>
                        </a>
                    </div>
            </div>
            <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="my-carousel-wrapper"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible={true}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                      },
                      mobile: {
                        breakpoint: {
                          max: 420,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 20,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 420,
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                      }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={2}
                    swipeable
                  >
                    {filtered.map((post,index)=> {
                        return(
                            <a 
                            className="front-more-posts-container" 
                            key={`${index}=more-posts`}
                            href={`/${findPath(post.categories[0])}/${post.id}`}
                            >
                                <div className="front-more-posts-img-wrapper">
                                <img src={post.jetpack_featured_media_url} alt="" />
                                <div><span className="more-posts-calendar"> <img src={calendar} alt="" /> </span>{postDate(post.date)} <span className="article-category">{findCategory(post.categories[0])}</span></div>
                                </div>
                                <div
                                className={isDark ? 'slider-txt isDark-Font' : 'slider-txt'}
                                dangerouslySetInnerHTML={{
                                    __html:
                                    decodedFilteredTitles[index].length >= 46
                                        ? `${decodedFilteredTitles[index].slice(0, 45)}...`
                                        : decodedFilteredTitles[index],
                                }}
                                ></div>
                            </a>
                        )
                    })}
              </Carousel>
              <Link 
              className="home-link"
              onClick={scrollToTop}
              to={title === 'ФУТБОЛЕН КЛУБ ЛЕВСКИ' ? '/football-club-levski' : `/${findPath(items[0].categories[0])}`}
              >
                <div className="front-all-levski">ВИЖ ВСИЧКИ</div> 
              </Link>
            </>
          )}
        </>
    )
};

export default FrontNews;