import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoaderPost from "../components/LoaderPost";
import { InlineFollowButtons, InlineShareButtons } from "sharethis-reactjs";
import LatestNews from "../components/LatestNews";
import Flashscore from "../components/Flashscore";
import AdComponent from "../components/AdComponent";
import findPath from "../hooks-and-funcs/FindPath";
import scrollToTop from "../hooks-and-funcs/ScrollToTop";
import postDate from "../hooks-and-funcs/postDate";
import MoreFromAuthor from "../components/MoreFromAuthorAndRelated";
import BreadCrumbs from "../components/BreadCrumbs";
import { Helmet } from "react-helmet";
import { useTheme } from "../hooks-and-funcs/ThemeStateContext";

const PostPage = () => {
  const params = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [previous, setPrevious] = useState('');
  const [next, setNext] = useState('');
  const contentRef = useRef(null);
  const {isDark, setIsDark} = useTheme();

  const youtubeSeries = 'https://www.youtube.com/embed/videoseries?si=so4FVKgg8VUv3xc4&amp;list=PLMxONK1QhCuZIU86bgmfZUghOF6qBKnT7'

  const [fbFrame, setFbFrame] = useState('');
  
  const getYoutubeVideoId = (link) => {

    const url = new URL(link);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get('v');

  };

  const[youtubeVideoId, setYoutubeVideoId] = useState('');

    const fetchPost = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `https://wp.gerena.bg/wp-json/wp/v2/posts/${params.postID}/?_embed`, {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache'
              }
            }
          );
          if (!response.ok) {
            throw new Error("Response issue");
          }
          const data = await response.json();


          /*if(data.content.rendered.includes('iframe')){
            data.content.rendered = data.content.rendered.replace(/<iframe.*?<\/iframe>/gi, '');
          }*/
          if(data.content.rendered.includes('iframe')){
            data.content.rendered = data.content.rendered.replace(
              /<iframe/g,
              '<iframe class="iframe-in-text"'
            );
          }
          if(data.excerpt.rendered.includes('iframe')){
            data.excerpt.rendered = data.excerpt.rendered.replace(
              /<iframe/g,
              '<iframe class="external-media-iframe"'
            );
          }

          setPost(data);

          if
          (data.excerpt.rendered.includes('youtube') 
          || data.excerpt.rendered.includes('facebook') 
          || data.excerpt.rendered.includes('vbox7'))
             {
              if(data.excerpt.rendered.includes('youtube')){
                let youtubeLink = '' ;
                let match = data.excerpt.rendered.match(/<p>(.*?)<\/p>/i);
                youtubeLink = match[1];
                const id = getYoutubeVideoId(youtubeLink);
                setYoutubeVideoId(id);
              }
              else if(data.excerpt.rendered.includes('facebook') || (data.excerpt.rendered.includes('vbox7'))){
                let match = data.excerpt.rendered.match(/<p>(.*?)<\/p>/i);
                setFbFrame(match[1]);
              }
          }

          /*if(data.meta.td_last_set_video){
            const id = getYoutubeVideoId(data.meta.td_last_set_video)
            setVideoId(id)
          } */

          const formattedDate = new Date(data.date).toISOString();

          const previousResponse = await fetch(
            `https://wp.gerena.bg/wp-json/wp/v2/posts?per_page=1&before=${formattedDate}&order=desc`
          );
          const previousData = await previousResponse.json();
          const previousPost = previousData.length > 0 ? previousData[0] : null;
          setPrevious(previousPost);

          const nextResponse = await fetch(
            `https://wp.gerena.bg/wp-json/wp/v2/posts?per_page=1&after=${formattedDate}&order=asc`
          );
          const nextData = await nextResponse.json();
          const nextPost = nextData.length > 0 ? nextData[0] : null;
          setNext(nextPost);

        } catch (error) {
          console.log(error);
        } finally { 
          setIsLoading(false);
        }
      };

      useEffect(()=>{
        fetchPost()
      },[params.postID]);


      useEffect(() => {
        const postText = contentRef.current;
        const title = document.getElementsByTagName('h1');
        if (postText) {
          const imgWrapper = postText.getElementsByTagName('figure');
          for (const wrapper of imgWrapper) {
            wrapper.removeAttribute('style');
            wrapper.removeAttribute('class');
            wrapper.style.margin = '0'
            wrapper.style.padding = '0'
          }

          const ul = postText.getElementsByTagName('ul');
          for (const wrapper of ul) {
            wrapper.removeAttribute('style');
            wrapper.removeAttribute('class');
            wrapper.style.margin = '0'
            wrapper.style.padding = '0'
          }

          for (const el of title) {
            el.removeAttribute('style');
            el.removeAttribute('class');
            el.style.margin = '0'
            el.style.padding = '0'
          }
          const imgElements = postText.getElementsByTagName('img');
            for (const img of imgElements) {
              img.removeAttribute('style');
              img.removeAttribute('class');
              img.classList.add('content-img')
            }
           
          /*const iframeEl = postText.getElementsByTagName('iframe');
          for (const iframe of iframeEl){
            iframe.removeAttribute('style');
            iframe.removeAttribute('class');
            iframe.classList.add('iframe-in-text');
            iframe.style.width = '0px';
          }*/
        }
      }, [post]);

      useEffect(()=> {
        if(!isLoading){
          if(post.content.rendered.includes('twitter-tweet')){
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://platform.twitter.com/widgets.js';
            const head = document.head || document.getElementsByTagName('head')[0];
            head.appendChild(script);

            return () => {
              head.removeChild(script);
            };
          }
        }
      },[isLoading])
    
    return(
        isLoading ? (
            <LoaderPost/>
          ) : (
            <section>
              <Helmet>
                <title>{post.title.rendered}</title>
                <meta property="og:title" content={post.title.rendered} />
                <meta property="og:description" content={`${post.content.rendered.slice(0, 49)}...`} />
                <meta property="og:image" content={post.jetpack_featured_media_url} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
              </Helmet>
              <article>
              <div className={isDark ? 'post-page-wrapper isDark' : 'post-page-wrapper'}>
                 <div 
                    className="single-post"
                    >
                      <BreadCrumbs
                      postTitle={post.title.rendered}
                      />
                      <div className="video">
                        {
                        fbFrame 
                        ? 
                          <div
                          dangerouslySetInnerHTML={{__html:
                            fbFrame
                          }}
                          ></div>
                        :
                          <iframe 
                          width="100%" 
                          height="100%" 
                          src={
                            youtubeVideoId
                            ? 
                            `https://www.youtube.com/embed/${youtubeVideoId}?start=1&mute=1&autoplay=1` 
                            : 
                            `${youtubeSeries}&start=1&autoplay=1&mute=1`
                          }  
                          title="YouTube video player" 
                          frameBorder="0"  
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                          allowFullScreen></iframe>
                        }
                      </div>
                         <h1
                            dangerouslySetInnerHTML={{
                            __html:
                            post.title.rendered
                        }} 
                        ></h1>
                        <h3>Автор: <span className="post-page-author">
                          <Link onClick={scrollToTop} to={`/author/${post._embedded.author[0].id}`}>{post._embedded.author[0].name}</Link>
                          </span> <span className="post-article-date" >Дата: {postDate(post.date)}</span></h3>
                        <img src={post.jetpack_featured_media_url} alt="" />
                        <div className="share-post">
                          <InlineShareButtons
                          className='share-btn-post'
                          config={{
                            alignment: 'center',
                            color: 'social',
                            enabled: 'true',
                            language: 'en',
                            networks: [
                              'facebook',
                              'messenger',
                              'whatsapp',
                              'viber',
                            ],
                            spacing: 20,
                            radius: 10,
                            disableAnimations: true,
                          }}
                          />
                        </div>
                        <div 
                        className='post-text'
                        ref={contentRef}
                        dangerouslySetInnerHTML={{
                            __html:
                            post.content.rendered
                        }}
                        ></div>
                      <div className="post-ad">
                        <AdComponent
                        className={'front-ad'}
                        adslot = {'4490618198'}
                        adclient={'ca-pub-2309670898258044'}
                        />
                      </div>
                      <div className="prev-next-post">
                        {
                          previous
                          ?
                          <a
                          href={`/${findPath(previous.categories[0])}/${previous.id}`}
                          >
                            <div className={isDark ? 'isDark-Font' : null}>Предишна статия</div>
                          </a>
                          :
                          null
                        }

                        {next 
                        ? 
                        <a
                        href={`/${findPath(next.categories[0])}/${next.id}`}
                        >
                          <div className={isDark ? 'isDark-Font' : null}>Следваща статия</div>
                        </a>
                        : 
                        null
                        }
                      </div>




                  </div>
                    <aside className="latest-wrapper">
                      <LatestNews/>
                      <Flashscore/>
                      <div className='post-follow-btns'>
                        <p>ПОСЛЕДВАЙ НИ</p>
                        <InlineFollowButtons
                        config={{
                          action: false,
                          alignment: 'justified',
                          enabled: true,
                          spacing: 20,
                          networks: [
                            'facebook',
                            'youtube',
                            'instagram',
                          ],
                          radius: 9,
                          profiles: {
                            youtube: '@Gerenabg',
                            facebook: 'gerena.bg',
                            instagram: 'gerena.bg',
                          }                                                   
                        }}
                        />
                      </div>
                    </aside>
                 </div>
                 <div className={isDark ? 'carousel-more isDark' : 'carousel-more'}>
                  {post && (<MoreFromAuthor
                        authorID = {post._embedded.author[0].id}
                        categoryInput = {post.categories[0]}
                        excludedPostId={post.id}/>)
                        }
                 </div>
              </article>
            </section>
          )
        
    )
}

export default PostPage;






