import React from "react";
import MainContent from "../components/MainContent";

const LevskiFirstTeam = ()=> {
    return (
        <MainContent
        category={208}
        >

        </MainContent>
    )
};

export default LevskiFirstTeam;