import React from "react";
import MainContent from "../components/MainContent";
const Levski = ()=> {
    return (
            <MainContent
            category = {216}
            >
            </MainContent>
    )
};

export default Levski;