import React from "react";
import { flashscore } from "../assetsExport";

const Flashscore = ()=> {
    return (
        <a href="https://www.flashscore.bg/team/levski/E5OOzgs4/" target="_blank" className="flashscore-post">
             <img src={flashscore} alt="Левски - резултати и статистика" />
        </a>
    )
};

export default Flashscore;