import React from "react";
import MainContent from "../components/MainContent";

const LevskiTransfers = ()=> {
    return(
        <MainContent
        category={210}
        >

        </MainContent>
    )
};

export default LevskiTransfers;